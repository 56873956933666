<template>
  <div class="layout">
    <div class="main-content">
      <router-view/>
    </div>
  </div>
</template>

<style>
#app {
  height:100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
body {
  margin:0;
  width:100vw;
  height:100vh;
  overflow:hidden;
}
.main-content {
  height:100%;
  width:100%;
}
.layout {
  width:100%;
  height:100%;
}
</style>
