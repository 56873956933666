<template>
  <div class="outer-layout">
    <div class="header">
      <select v-model="selectedPattern" class="pattern-select">
        <option v-for="pattern in patterns" :key="pattern.id" :value="pattern.id">{{ pattern.caption }}</option>
      </select>
      <div>{{ patterns[selectedPattern].description}}</div>
    </div>
    <div class="content-layout">
      <div class="caption">XML</div>
      <textarea v-model="xml[selectedPattern]"/>
      <div class="caption">XML Style Sheet</div>
      <textarea v-model="xslt[selectedPattern]"/>
      <div class="caption">HTML</div>
      <textarea v-model="html"/>
      <div class="caption">Appearance</div>
      <div id="result"/>
    </div>
    <div>
      <button @click="convert($event)">Convert</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

@Options({
  components: {
    HelloWorld,
  },
})
export default class HomeView extends Vue {
  convert(event: Event) {
    let processor = new XSLTProcessor();
    let parser = new DOMParser();
    processor.importStylesheet(parser.parseFromString(this.xslt[this.selectedPattern], "text/xml"));
    let doc = processor.transformToFragment(parser.parseFromString(this.xml[this.selectedPattern], "text/xml"), document);
    let resultEl = document.getElementById("result");
    if (resultEl){
      resultEl.innerHTML = "";
      if (doc) {
        resultEl.appendChild(doc);
        this.html = resultEl.innerHTML;
//      resultEl.innerHTML = doc.nodeName;
      
      }
      else {
        resultEl.innerHTML="<div>Error</div>"
      }
    }
  }
  selectedPattern = 0;
  patterns = [
    {id:0, caption: "レベルに応じてリーダーを固定的に決定する", description: "データXMLとしては理想的であるが文書ごとに変更できない"},
    {id:1, caption: "リストにリーダーの種類を入れる", description: "視覚的要素がXMLに入るが文書ごとに変更できる"},
    {id:2, caption: "アイテムごとにリーダーを属性で指定する", description: "法令標準XMLスキーマに近いが要素IDとして扱う方がよい"},
    {id:3, caption: "アイテムごとにリーダーを要素で指定する", description: "データXMLとしては問題だが、タグをとった場合に元のテキストになる"},
  ]
  html = "";
  xslt = [
    // xslt[0]
`<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet version="2.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
<xsl:output method="html" />
<xsl:template match="List">
  <ul>
    <xsl:attribute name="class">
      <xsl:choose>
        <xsl:when test="count(ancestor::List)=0">
          leader-decimal          
        </xsl:when> 
        <xsl:when test="count(ancestor::List)=1">
          leader-lower-alpha
        </xsl:when> 
        <xsl:when test="count(ancestor::List)=2">
          leader-katakana-i      
        </xsl:when> 
      </xsl:choose>
      <xsl:value-of select="concat('list-level-', count(ancestor::List)+1)" />
    </xsl:attribute>
    <xsl:apply-templates />
  </ul>
</xsl:template>
<xsl:template match="ListItem">
  <li>
    <xsl:apply-templates />
  </li>
</xsl:template>
<xsl:template match="ListItem/Paragraph">
  <p class="in-list">
    <xsl:apply-templates />
  </p>
</xsl:template>

</xsl:stylesheet>  
`,
// xslt[1]
`<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet version="2.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
<xsl:output method="html" />
<xsl:template match="List">
  <ul>
    <xsl:attribute name="class">
      <xsl:value-of select="@leader" />
    </xsl:attribute>
    <xsl:apply-templates />
  </ul>
</xsl:template>
<xsl:template match="ListItem">
  <li>
    <xsl:apply-templates />
  </li>
</xsl:template>
<xsl:template match="ListItem/Paragraph">
  <p class="in-list">
    <xsl:apply-templates />
  </p>
</xsl:template>

</xsl:stylesheet>  
`,
// xslt[2]
`<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet version="2.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
<xsl:output method="html" />
<xsl:template match="List">
  <ul class="list-no-leader">
    <xsl:apply-templates />
  </ul>
</xsl:template>
<xsl:template match="ListItem">
  <li>
    <div class="list-item-layout">
      <div class="list-leader">
        <xsl:value-of select="@Num" />
      </div>
      <div>
        <xsl:apply-templates />
      </div>
    </div>
  </li>
</xsl:template>
<xsl:template match="ListItem/Paragraph">
  <p class="in-list">
    <xsl:apply-templates />
  </p>
</xsl:template>

</xsl:stylesheet>  
`,
//xslt[3]
`<?xml version="1.0" encoding="UTF-8"?>
<xsl:stylesheet version="2.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
<xsl:output method="html" />
<xsl:template match="List">
  <ul class="list-no-leader">
    <xsl:apply-templates />
  </ul>
</xsl:template>
<xsl:template match="ListItem">
  <li>
    <div class="list-item-layout">
      <div class="list-leader">
        <xsl:value-of select="Num/text()" />
      </div>
      <div>
        <xsl:apply-templates select="*[not(self::Num)]"/>
        <xsl:value-of select="text()" />
      </div>
    </div>
  </li>
</xsl:template>
<xsl:template match="ListItem/Paragraph">
  <p class="in-list">
    <xsl:apply-templates />
  </p>
</xsl:template>
</xsl:stylesheet>
`];
  xml = [`<?xml version="1.0" encoding="UTF-8" ?>
<List>
  <ListItem>Item 1</ListItem>
  <ListItem>Item 2</ListItem>
  <ListItem>
    <Paragraph>Item 3</Paragraph>
    <List>
      <ListItem>
      <Paragraph>Item 3-1</Paragraph>
        <List>
          <ListItem>Item 3-1-1</ListItem>
          <ListItem>Item 3-1-2</ListItem>
          <ListItem>Item 3-1-3</ListItem>
        </List>
      </ListItem>
      <ListItem>Item 3-2</ListItem>
      <ListItem>Item 3-3</ListItem>
    </List>
  </ListItem>
</List>
`,
`<?xml version="1.0" encoding="UTF-8" ?>
<List leader="leader-decimal">
  <ListItem>Item 1</ListItem>
  <ListItem>Item 2</ListItem>
  <ListItem>
    <Paragraph>Item 3</Paragraph>
    <List leader="leader-lower-alpha">
      <ListItem>
      <Paragraph>Item 3-1</Paragraph>
        <List leader="leader-decimal-kanji">
          <ListItem>Item 3-1-1</ListItem>
          <ListItem>Item 3-1-2</ListItem>
          <ListItem>Item 3-1-3</ListItem>
        </List>
      </ListItem>
      <ListItem>Item 3-2</ListItem>
      <ListItem>Item 3-3</ListItem>
    </List>
  </ListItem>
</List>
`,
`<?xml version="1.0" encoding="UTF-8" ?>
<List>
  <ListItem Num="1.">Item 1</ListItem>
  <ListItem Num="2.">Item 2</ListItem>
  <ListItem Num="3.">
    <Paragraph>Item 3</Paragraph>
    <List>
      <ListItem Num="a.">
        <Paragraph>Item 3-1</Paragraph>
        <List>
          <ListItem Num="（一）">Item 3-1-1</ListItem>
          <ListItem Num="（二）">Item 3-1-2</ListItem>
          <ListItem Num="（三）">Item 3-1-3</ListItem>
        </List>
      </ListItem>
      <ListItem Num="b.">Item 3-2</ListItem>
      <ListItem Num="c.">Item 3-3</ListItem>
    </List>
  </ListItem>
</List>
`,
`<?xml version="1.0" encoding="UTF-8" ?>
<List>
  <ListItem ><Num>1.</Num>Item 1</ListItem>
  <ListItem ><Num>2.</Num>Item 2</ListItem>
  <ListItem ><Num>3.</Num>
    <Paragraph>Item 3</Paragraph>
    <List>
      <ListItem ><Num>a.</Num>
        <Paragraph>Item 3-1</Paragraph>
        <List>
          <ListItem ><Num>（一）</Num>Item 3-1-1</ListItem>
          <ListItem ><Num>（二）</Num>Item 3-1-2</ListItem>
          <ListItem ><Num>（三）</Num>Item 3-1-3</ListItem>
        </List>
      </ListItem>
      <ListItem ><Num>b.</Num>Item 3-2</ListItem>
      <ListItem ><Num>c.</Num>Item 3-3</ListItem>
    </List>
  </ListItem>
</List>
`
];
}
</script>

<style>
  .outer-layout {
    height:100%;
    display:grid;
    grid-template-rows: 8% 84% 8%;
    grid-auto-flow: column;
  }
  .content-layout {
    height:100%;
    display:grid;
    box-sizing: border-box;
    padding: 16px;
    gap: 8px;
    grid-template-rows: max-content 1fr max-content 1fr;
    grid-template-columns: 50% 50%;
    grid-auto-flow: column;
  }
  #result {
     border: 1px solid black;
  }
  .caption {
    text-align:left;
  }
  .pattern-select {
    padding: 0.3em 1em;
    height: 2em;
  }
  .header {
    padding: 8px 2em;
    display:flex;
    justify-content: space-between;
  }
  li {
    text-align:left;
  }
  ul.leader-decimal {
    list-style-type:decimal;
  }
  ul.leader-katakana-a, ul.leader-katakana-i, ul.leader-decimal-kanji {
    list-style-type:none;
  }

  ul.leader-decimal-kanji>li:nth-child(1)::before {
    content: "(一) "
  }
  ul.leader-decimal-kanji>li:nth-child(2)::before {
    content: "(二) "
  }
  ul.leader-decimal-kanji>li:nth-child(3)::before {
    content: "(三) "
  }
  ul.leader-decimal-kanji>li:nth-child(4)::before {
    content: "(四) "
  }
  ul.leader-decimal-kanji>li:nth-child(5)::before {
    content: "(五) "
  }
  ul.leader-decimal-kanji>li:nth-child(6)::before {
    content: "(六) "
  }
  ul.leader-decimal-kanji>li:nth-child(7)::before {
    content: "(七) "
  }
  ul.leader-decimal-kanji>li:nth-child(8)::before {
    content: "(八) "
  }
  ul.leader-decimal-kanji>li:nth-child(9)::before {
    content: "(九) "
  }
  ul.leader-decimal-kanji>li:nth-child(10)::before {
    content: "(十) "
  }
  ul.leader-lower-alpha>li {
    list-style-type:lower-alpha;
  }
  ul.leader-upper-alpha>li {
    list-style-type:upper-alpha;
  }
  ul.leader-katakana-a>li:nth-child(1)::before {
    content: "(ア) "
  }
  ul.leader-katakana-a>li:nth-child(2)::before {
    content: "(イ) "
  }
  ul.leader-katakana-a>li:nth-child(3)::before {
    content: "(ウ) "
  }
  ul.leader-katakana-a>li:nth-child(4)::before {
    content: "(エ) "
  }
  ul.leader-katakana-a>li:nth-child(5)::before {
    content: "(オ) "
  }
  ul.leader-katakana-a>li:nth-child(6)::before {
    content: "(カ) "
  }
  ul.leader-katakana-a>li:nth-child(7)::before {
    content: "(キ) "
  }
  ul.leader-katakana-a>li:nth-child(8)::before {
    content: "(ク) "
  }
  ul.leader-katakana-a>li:nth-child(9)::before {
    content: "(ケ) "
  }
  ul.leader-katakana-a>li:nth-child(10)::before {
    content: "(コ) "
  }
  ul.leader-katakana-i>li:nth-child(1)::before {
    content: "(イ) "
  }
  ul.leader-katakana-i>li:nth-child(2)::before {
    content: "(ロ) "
  }
  ul.leader-katakana-i>li:nth-child(3)::before {
    content: "(ハ) "
  }
  ul.leader-katakana-i>li:nth-child(4)::before {
    content: "(ニ) "
  }
  ul.leader-katakana-i>li:nth-child(5)::before {
    content: "(ホ) "
  }
  ul.leader-katakana-i>li:nth-child(6)::before {
    content: "(ヘ) "
  }
  ul.leader-katakana-i>li:nth-child(7)::before {
    content: "(ト) "
  }
  ul.leader-katakana-i>li:nth-child(8)::before {
    content: "(チ) "
  }
  ul.leader-katakana-i>li:nth-child(9)::before {
    content: "(リ) "
  }
  ul.leader-katakana-i>li:nth-child(10)::before {
    content: "(ヌ) "
  }
  .list-no-leader {
    list-style-type: none;
    padding-left: 1em;
  }
  .list-item-layout {
    display:flex;
    flex-direction:row;
  }
  .list-leader {
    margin-right: 4px;
  }
  p.in-list {
    margin-top: 0;
    margin-bottom: 0;
  }
</style>
