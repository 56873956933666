import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "outer-layout" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "content-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPattern) = $event)),
        class: "pattern-select"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patterns, (pattern) => {
          return (_openBlock(), _createElementBlock("option", {
            key: pattern.id,
            value: pattern.id
          }, _toDisplayString(pattern.caption), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.selectedPattern]
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.patterns[_ctx.selectedPattern].description), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "caption" }, "XML", -1)),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.xml[_ctx.selectedPattern]) = $event))
      }, null, 512), [
        [_vModelText, _ctx.xml[_ctx.selectedPattern]]
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "caption" }, "XML Style Sheet", -1)),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.xslt[_ctx.selectedPattern]) = $event))
      }, null, 512), [
        [_vModelText, _ctx.xslt[_ctx.selectedPattern]]
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "caption" }, "HTML", -1)),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.html) = $event))
      }, null, 512), [
        [_vModelText, _ctx.html]
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "caption" }, "Appearance", -1)),
      _cache[9] || (_cache[9] = _createElementVNode("div", { id: "result" }, null, -1))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.convert($event)))
      }, "Convert")
    ])
  ]))
}